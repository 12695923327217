import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import StudentList from './StudentList';
import StudentDetails from './StudentDetails';

const StudentPage = () => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const { SRNo } = useParams<{ SRNo: string }>();
  const navigate = useNavigate();

  const handleSelectStudent = (SRNo: string) => {
    if (hasUnsavedChanges) {
      const confirmLeave = window.confirm('You have unsaved changes. Are you sure you want to switch students without saving?');
      if (!confirmLeave) return;
    }
    setHasUnsavedChanges(false);
    navigate(`/students/${SRNo}`);
  };

  return (
    <div style={{ display: 'flex' }}>
      <div className="Left-pane" style={{ flex: "0 0 auto" }}>
        <StudentList onSelectStudent={handleSelectStudent} unsavedChangeStatus={hasUnsavedChanges} />
      </div>
      <div style={{ flex: 2 }}>
        <StudentDetails SRNo={SRNo || ''} setUnsavedChangeStatus={setHasUnsavedChanges}/>
      </div>
    </div>
  );
};

export default StudentPage;