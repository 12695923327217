import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3002';

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const { checkAuth } = useAuth();

  const handleGoogleLoginSuccess = async (credentialResponse: any) => {
    try {
      const response = await axios.post(`${BACKEND_URL}/api/login`, { credential: credentialResponse.credential }, { withCredentials: true });
      if (response.data.success) {
        await checkAuth();
        navigate('/landing');
      } else {
        console.error('Login failed:', response.data.message);
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  const handleGoogleLoginError = () => {
    console.error('Google login failed');
  };

  return (
    <GoogleOAuthProvider clientId="5153499728-l14idtoggqr4j5ckbcvnr9tv1j24vh56.apps.googleusercontent.com">
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <GoogleLogin
          onSuccess={handleGoogleLoginSuccess}
          onError={handleGoogleLoginError}
        />
      </Container>
    </GoogleOAuthProvider>
  );
};

export default LoginPage;