import React, { useState, useEffect } from 'react';
import { Table, Form, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import NewReceiptForm from './NewReceiptForm';
import { Receipt } from '../types';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3002';

const ReceiptList: React.FC = () => {
  const [receipts, setReceipts] = useState<Receipt[]>([]);
  const [searchName, setSearchName] = useState('');
  const [searchType, setSearchType] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [editingReceiptId, setEditingReceiptId] = useState<string | null>(null);

  useEffect(() => {
    fetchReceipts();
  }, []);

  const fetchReceipts = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/accountsapi/receipts`);
      if (!response.ok) {
        throw new Error('Failed to fetch receipts');
      }
      const data = await response.json();
      setReceipts(data);
    } catch (error) {
      console.error('Error fetching receipts:', error);
    }
  };

  const handleSearch = async () => {
    try {
      let queryParams = new URLSearchParams();
      if (searchName) queryParams.append('name', searchName);
      if (searchType) queryParams.append('type', searchType);
      if (startDate) queryParams.append('startDate', startDate.toISOString().split('T')[0]);
      if (endDate) queryParams.append('endDate', endDate.toISOString().split('T')[0]);

      const response = await fetch(`${BACKEND_URL}/accountsapi/receipts/search?${queryParams.toString()}`);
      if (!response.ok) {
        throw new Error('Failed to search receipts');
      }
      const data = await response.json();
      setReceipts(data);
    } catch (error) {
      console.error('Error searching receipts:', error);
    }
  };

  const handleEdit = (receiptId: string) => {
    setEditingReceiptId(receiptId);
  };

  const handleEditComplete = () => {
    setEditingReceiptId(null);
    fetchReceipts(); // Refresh the list after edit
  };

  const handleCancelEdit = () => {
    setEditingReceiptId(null);
  };

  const sortedReceipts = [...receipts].sort((a, b) => {
    return parseInt(b.receiptId) - parseInt(a.receiptId);
  });

  const getPaymentType = (receipt: Receipt): string => {
    if (receipt.receiptType === 'cancelled') {
      return `Cancelled: ${receipt.cancellationReason || 'No reason provided'}`;
    }
    if (receipt.depositMedium === 'split') {
      return 'Split (Cash + UPI)';
    }
    if (receipt.depositMedium === 'upi' && receipt.upiId) {
      return `UPI (${receipt.upiId})`;
    }
    return receipt.depositMedium ? receipt.depositMedium.charAt(0).toUpperCase() + receipt.depositMedium.slice(1) : 'N/A';
  };

  return (
    <div>
      <Form className="mb-3">
        <Form.Group className="mb-2">
          <Form.Control
            type="text"
            placeholder="Search by name"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Control
            as="select"
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
          >
            <option value="">All Types</option>
            <option value="fees">Fees</option>
            <option value="donation">Donation</option>
            <option value="cancelled">Cancelled</option>
          </Form.Control>
        </Form.Group>
        <Form.Group className="mb-2">
          <DatePicker
            selected={startDate}
            onChange={(date: Date | null) => setStartDate(date)}
            placeholderText="Start Date"
            dateFormat="dd/MM/yyyy"
            minDate={new Date("01/01/1900")}
            maxDate={new Date()}
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <DatePicker
            selected={endDate}
            onChange={(date: Date | null) => setEndDate(date)}
            placeholderText="End Date"
            dateFormat="dd/MM/yyyy"
            minDate={new Date("01/01/1900")}
            maxDate={new Date()}
          />
        </Form.Group>
        <Button onClick={handleSearch}>Search</Button>
      </Form>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Receipt ID</th>
            <th>Date</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Name</th>
            <th>Payment Type / Cancellation Reason</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedReceipts.map((receipt) => (
            <React.Fragment key={receipt._id}>
              <tr>
                <td>{receipt.receiptId}</td>
                <td>{new Date(receipt.date).toLocaleDateString()}</td>
                <td>{receipt.receiptType}</td>
                <td>{receipt.amount}</td>
                <td>{receipt.studentDetails?.studentName || receipt.donorName}</td>
                <td>{getPaymentType(receipt)}</td>
                <td>
                  <FontAwesomeIcon 
                    icon={faPencilAlt} 
                    onClick={() => handleEdit(receipt._id)}
                    style={{ cursor: 'pointer' }}
                  />
                </td>
              </tr>
              {editingReceiptId === receipt._id && (
                <tr>
                  <td colSpan={7}>
                    <NewReceiptForm
                      initialData={receipt}
                      onComplete={handleEditComplete}
                      onCancel={handleCancelEdit}
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ReceiptList;
