import React, { useEffect, useState } from 'react';
import { Container, Table, Card, Accordion } from 'react-bootstrap';
import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3002';

// Helper function to format amount with commas
const formatAmount = (amount: number): string => {
  return `Rs. ${amount.toLocaleString('en-IN')}`;
};

interface Receipt {
  receiptId: string;
  date: string;
  amount: number;
}

interface Student {
  studentName: string;
  srno: string;
  classDesc: string;
  receipts: Receipt[];
}

interface ClassGroup {
  className: string;
  students: Student[];
}

const FeeSubmissionReport: React.FC = () => {
  const [classGroups, setClassGroups] = useState<ClassGroup[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/accountsapi/getFeeSubmissionReport`);
        setClassGroups(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load fee submission report');
        setLoading(false);
      }
    };

    fetchReport();
  }, []);

  const calculateTotalPaid = (receipts: Receipt[]): number => {
    return receipts.reduce((sum, receipt) => sum + receipt.amount, 0);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-danger">{error}</div>;

  return (
    <Container fluid>
      <h2 className="my-4">Fee Submission Report</h2>
      
      <Accordion>
        {classGroups.map((classGroup, index) => (
          <Accordion.Item key={index} eventKey={index.toString()}>
            <Accordion.Header>
              {classGroup.className} ({classGroup.students.length} students)
            </Accordion.Header>
            <Accordion.Body>
              <Card>
                <Card.Body>
                  <Table responsive striped bordered hover>
                    <thead>
                      <tr>
                        <th>SR No</th>
                        <th>Student Name</th>
                        <th>Payment Information</th>
                        <th>Total Paid</th>
                      </tr>
                    </thead>
                    <tbody>
                      {classGroup.students.map((student, studentIndex) => (
                        <tr key={studentIndex}>
                          <td>{student.srno}</td>
                          <td>{student.studentName}</td>
                          <td>
                            {student.receipts.length > 0 ? (
                              <div>
                                {student.receipts.map((receipt, receiptIndex) => (
                                  <div key={receiptIndex} className="mb-1">
                                    {receipt.date} - {formatAmount(receipt.amount)} - Receipt #{receipt.receiptId}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <span className="text-muted">No payments</span>
                            )}
                          </td>
                          <td>{formatAmount(calculateTotalPaid(student.receipts))}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </Container>
  );
};

export default FeeSubmissionReport; 