import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import { ICalendarEvent } from '../types/calendar';
import { ISyllabusPlan } from '../types/syllabusPlan';
import './SyllabusPlanningComponent.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3002';

interface SyllabusPlanningComponentProps {
  academicYear: string;
  className: string;
}

const SyllabusPlanningComponent: React.FC<SyllabusPlanningComponentProps> = ({ academicYear, className }) => {
  const [events, setEvents] = useState<ICalendarEvent[]>([]);
  const [syllabusPlans, setSyllabusPlans] = useState<ISyllabusPlan[]>([]);
  const [startDate, setStartDate] = useState<moment.Moment>(moment());
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setDateRange();
    fetchEvents();
    fetchSyllabusPlans();
  }, [academicYear, className]);

  useEffect(() => {
    scrollToCurrentDate();
  }, [startDate]);

  const setDateRange = () => {
    const [startYear, endYear] = academicYear.split('-').map(Number);
    setStartDate(moment(`${startYear}-04-01`));
    setEndDate(moment(`${endYear}-03-31`));
  };

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/calendarapi/events/${academicYear}`);
      setEvents(response.data || []);
    } catch (error) {
      console.error('Error fetching events:', error);
      setEvents([]);
    }
  };

  const fetchSyllabusPlans = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/syllabusapi/syllabus-plans/filter`, {
        params: { academicYear, class: className }
      });
      setSyllabusPlans(response.data || []);
    } catch (error) {
      console.error('Error fetching syllabus plans:', error);
      setSyllabusPlans([]);
    }
  };

  const scrollToCurrentDate = () => {
    if (tableRef.current) {
      const currentDate = moment();
      const daysSinceStart = currentDate.diff(startDate, 'days');
      const rowHeight = 30; // Adjust this value based on your CSS
      tableRef.current.scrollTop = daysSinceStart * rowHeight;
    }
  };

  const renderTableHeader = () => {
    return (
      <div className="table-header">
        <div className="header-cell date-header">Date</div>
        <div className="header-cell event-header">Events</div>
        {syllabusPlans.map((plan) => (
          <div key={plan.subject} className="header-cell subject-header">{plan.subject}</div>
        ))}
      </div>
    );
  };

  const renderTableBody = () => {
    const days = endDate.diff(startDate, 'days') + 1;
    return Array.from({ length: days }).map((_, index) => {
      const currentDate = moment(startDate).add(index, 'days');
      const dateString = currentDate.format('DD-MM-YYYY (ddd)');
      const isSunday = currentDate.day() === 0;
      
      return (
        <div key={dateString} className={`table-row ${isSunday ? 'sunday' : ''}`}>
          <div className="cell date-cell">{dateString}</div>
          <div className="cell event-cell">
            {events.filter(event => 
              moment(event.startDate).isSameOrBefore(currentDate) && 
              moment(event.endDate).isSameOrAfter(currentDate)
            ).map(event => event.name).join(', ')}
          </div>
          {syllabusPlans.map((plan) => (
            <div key={plan.subject} className="cell subject-cell">
              {/* Add syllabus plan details here */}
            </div>
          ))}
        </div>
      );
    });
  };

  return (
    <div className="syllabus-planning-component">
      {renderTableHeader()}
      <div className="table-body" ref={tableRef}>
        {renderTableBody()}
      </div>
    </div>
  );
};

export default SyllabusPlanningComponent;
