import React, { useState } from 'react';
import { Nav, Tab, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

interface TabContent {
  title: string;
  content: React.ReactNode;
}

interface Props {
  tabs: TabContent[];
}

const Tabs: React.FC<Props> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState<string>('0');

  return (
    <Tab.Container activeKey={activeTab} onSelect={(k) => setActiveTab(k || '0')}>
      <Nav variant="tabs">
        {tabs.map((tab, index) => (
          <Nav.Item key={index}>
            <Nav.Link eventKey={index.toString()}>{tab.title}</Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <Tab.Content>
        {tabs.map((tab, index) => (
          <Tab.Pane eventKey={index.toString()} key={index}>
            {tab.content}
          </Tab.Pane>
        ))}
      </Tab.Content>
    </Tab.Container>
  );
};

export default Tabs;
