import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Row, Col, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ITransaction, TransactionType, TransactionMethod } from '../types/transaction';

interface NewTransactionFormProps {
  initialData?: ITransaction;
  onComplete?: () => void;
  onCancel?: () => void;
}
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3002';

const NewTransactionForm: React.FC<NewTransactionFormProps> = ({ initialData, onComplete, onCancel }) => {
  const [transactionType, setTransactionType] = useState<TransactionType>(TransactionType.DEPOSIT);
  const [date, setDate] = useState<Date>(new Date());
  const [amount, setAmount] = useState<number>(0);
  const [purpose, setPurpose] = useState<string>('');
  const [method, setMethod] = useState<TransactionMethod>(TransactionMethod.CASH);
  const [upiId, setUpiId] = useState<string>('');
  const [chequeNumber, setChequeNumber] = useState<string>('');
  const [bankName, setBankName] = useState<string>('');
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [utrNumber, setUtrNumber] = useState<string>('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [transactionToConfirm, setTransactionToConfirm] = useState<any>(null);

  useEffect(() => {
    if (initialData) {
      setTransactionType(initialData.type);
      setDate(new Date(initialData.date));
      setAmount(initialData.amount);
      setPurpose(initialData.purpose);
      setMethod(initialData.method);
      setUpiId(initialData.metadata.upiId || '');
      setChequeNumber(initialData.metadata.chequeNumber || '');
      setBankName(initialData.metadata.bankName || '');
      setAccountNumber(initialData.metadata.accountNumber || '');
      setUtrNumber(initialData.metadata.utrNumber || '');
    }
  }, [initialData]);

  const validateForm = (): boolean => {
    const newErrors: string[] = [];

    if (!date) newErrors.push("Transaction date is required");
    if (amount <= 0) newErrors.push("Amount must be greater than 0");
    if (!purpose.trim()) newErrors.push("Purpose is required");

    if (method === TransactionMethod.UPI && !upiId.trim()) {
      newErrors.push("UPI ID is required for UPI transactions");
    }

    if (method === TransactionMethod.CHEQUE) {
      if (!chequeNumber.trim()) newErrors.push("Cheque number is required");
      if (!bankName.trim()) newErrors.push("Bank name is required");
    }

    if (method === TransactionMethod.NEFT) {
      if (!accountNumber.trim()) newErrors.push("Account number is required");
      if (!utrNumber.trim()) newErrors.push("UTR number is required");
    }

    setErrorMessages(newErrors);
    if (newErrors.length > 0) {
      setShowErrorModal(true);
      return false;
    }
    return true;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    const transactionData = {
      type: transactionType,
      date,
      amount,
      purpose,
      method,
      metadata: {
        ...(method === TransactionMethod.UPI && { upiId }),
        ...(method === TransactionMethod.CHEQUE && { chequeNumber, bankName }),
        ...(method === TransactionMethod.NEFT && { accountNumber, utrNumber }),
      },
    };
    setTransactionToConfirm(transactionData);
    setShowConfirmModal(true);
  };

  const handleConfirmSubmit = async () => {
    setError(null);
    setSuccess(false);

    try {
      const url = initialData 
        ? `${BACKEND_URL}/accountsapi/transactions/${initialData._id}`
        : `${BACKEND_URL}/accountsapi/transactions`;
      const method = initialData ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(transactionToConfirm),
      });

      if (!response.ok) {
        throw new Error('Failed to save transaction');
      }

      setSuccess(true);
      setShowConfirmModal(false);
      if (onComplete) {
        onComplete();
      } else {
        resetForm();
      }
    } catch (err) {
      setError('Failed to save transaction. Please try again.');
    }
  };

  const resetForm = () => {
    setTransactionType(TransactionType.DEPOSIT);
    setDate(new Date());
    setAmount(0);
    setPurpose('');
    setMethod(TransactionMethod.CASH);
    setUpiId('');
    setChequeNumber('');
    setBankName('');
    setAccountNumber('');
    setUtrNumber('');
  };

  const handleWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Transaction Type</Form.Label>
              <Form.Select 
                value={transactionType} 
                onChange={(e) => setTransactionType(e.target.value as TransactionType)}
              >
                {Object.values(TransactionType).map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Date</Form.Label>
              <DatePicker
                selected={date}
                onChange={(date: Date | null) => setDate(date || new Date())}
                className="form-control"
                dateFormat="dd/MM/yyyy"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                value={amount}
                onChange={(e) => setAmount(Number(e.target.value))}
                onWheel={handleWheel}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Purpose</Form.Label>
              <Form.Control
                type="text"
                value={purpose}
                onChange={(e) => setPurpose(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3">
          <Form.Label>Transaction Method</Form.Label>
          <Form.Select 
            value={method} 
            onChange={(e) => setMethod(e.target.value as TransactionMethod)}
          >
            {Object.values(TransactionMethod).map(method => (
              <option key={method} value={method}>{method}</option>
            ))}
          </Form.Select>
        </Form.Group>

        {method === TransactionMethod.UPI && (
          <Form.Group className="mb-3">
            <Form.Label>UPI ID</Form.Label>
            <Form.Control
              type="text"
              value={upiId}
              onChange={(e) => setUpiId(e.target.value)}
              required
            />
          </Form.Group>
        )}

        {method === TransactionMethod.CHEQUE && (
          <>
            <Form.Group className="mb-3">
              <Form.Label>Cheque Number</Form.Label>
              <Form.Control
                type="text"
                value={chequeNumber}
                onChange={(e) => setChequeNumber(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Bank Name</Form.Label>
              <Form.Control
                type="text"
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
                required
              />
            </Form.Group>
          </>
        )}

        {method === TransactionMethod.NEFT && (
          <>
            <Form.Group className="mb-3">
              <Form.Label>Account Number</Form.Label>
              <Form.Control
                type="text"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>UTR Number</Form.Label>
              <Form.Control
                type="text"
                value={utrNumber}
                onChange={(e) => setUtrNumber(e.target.value)}
                required
              />
            </Form.Group>
          </>
        )}

        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">Transaction saved successfully!</Alert>}
        
        <Button type="submit" className="me-2">
          {initialData ? 'Update' : 'Create'} Transaction
        </Button>
        {onCancel && <Button variant="secondary" onClick={onCancel}>Cancel</Button>}
      </Form>

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Transaction Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {transactionToConfirm && (
            <>
              <p>Type: {transactionToConfirm.type}</p>
              <p>Date: {transactionToConfirm.date.toLocaleDateString()}</p>
              <p>Amount: {transactionToConfirm.amount}</p>
              <p>Purpose: {transactionToConfirm.purpose}</p>
              <p>Method: {transactionToConfirm.method}</p>
              {transactionToConfirm.method === TransactionMethod.UPI && (
                <p>UPI ID: {transactionToConfirm.metadata.upiId}</p>
              )}
              {transactionToConfirm.method === TransactionMethod.CHEQUE && (
                <>
                  <p>Cheque Number: {transactionToConfirm.metadata.chequeNumber}</p>
                  <p>Bank Name: {transactionToConfirm.metadata.bankName}</p>
                </>
              )}
              {transactionToConfirm.method === TransactionMethod.NEFT && (
                <>
                  <p>Account Number: {transactionToConfirm.metadata.accountNumber}</p>
                  <p>UTR Number: {transactionToConfirm.metadata.utrNumber}</p>
                </>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmSubmit}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Form Validation Errors</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {errorMessages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewTransactionForm;
