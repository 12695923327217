import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import CalendarComponent from './CalendarComponent';
import { ICalendarEvent } from '../types/calendar';
import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3002';

const CalendarPlanningPage: React.FC = () => {
  const [academicYear, setAcademicYear] = useState<string>('');
  const [events, setEvents] = useState<ICalendarEvent[]>([]);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (academicYear) {
      fetchEvents();
    }
  }, [academicYear]);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/calendarapi/events/${academicYear}`);
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const handleEventAdd = (newEvent: ICalendarEvent) => {
    setEvents([...events, newEvent]);
  };

  const handleEventUpdate = (updatedEvent: ICalendarEvent) => {
    setEvents(events.map(event => event._id === updatedEvent._id ? updatedEvent : event));
  };

  const handleEventDelete = (eventId: string) => {
    setEvents(events.filter(event => event._id !== eventId));
  };

  const toggleEditMode = () => {
    setIsEditable(!isEditable);
  };

  return (
    <Container>
      <h1>Calendar Planning</h1>
      <Row className="mb-3">
        <Col md={6}>
          <Form.Group>
            <Form.Label>Select Academic Year</Form.Label>
            <Form.Control
              as="select"
              value={academicYear}
              onChange={(e) => setAcademicYear(e.target.value)}
            >
              <option value="">Select Year</option>
              <option value="2023-2024">2023-2024</option>
              <option value="2024-2025">2024-2025</option>
              <option value="2025-2026">2025-2026</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={6} className="d-flex align-items-end">
          <Button onClick={toggleEditMode} variant={isEditable ? "outline-primary" : "primary"}>
            {isEditable ? "Switch to View Mode" : "Switch to Edit Mode"}
          </Button>
        </Col>
      </Row>
      {academicYear && (
        <CalendarComponent
          events={events}
          academicYear={academicYear}
          onEventAdd={handleEventAdd}
          onEventUpdate={handleEventUpdate}
          onEventDelete={handleEventDelete}
          isEditable={isEditable}
        />
      )}
    </Container>
  );
};

export default CalendarPlanningPage;
