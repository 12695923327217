// StudentList.tsx
import React, { useState, useEffect } from 'react';
import SearchBar from './SearchBar';
import './StudentList.css';

interface Student {
  _id: string;
  studentName: string;
  classDesc: string;
  SRNo: string;
}

interface Props {
  onSelectStudent: (id: string) => void;
  unsavedChangeStatus: boolean;
}
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3002';

const StudentList: React.FC<Props> = ({ onSelectStudent, unsavedChangeStatus }) => {
  const [students, setStudents] = useState<Student[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedGroups, setExpandedGroups] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    fetch(`${BACKEND_URL}/studentapi/students`)
      .then(response => response.json())
      .then(data => setStudents(data))
      .catch(error => console.error('Error fetching students:', error));
  }, []);

  const filteredStudents = students.filter(student =>
    student.studentName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const classGroups: { [key: string]: number } = {};
  filteredStudents.forEach(student => {
    if (classGroups[student.classDesc]) {
      classGroups[student.classDesc]++;
    } else {
      classGroups[student.classDesc] = 1;
    }
  });

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (unsavedChangeStatus) {
        event.preventDefault();
        event.returnValue = ''; // Chrome requires returnValue to be set
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChangeStatus]);

  const handleSelectStudent = (id: string) => {
    if (unsavedChangeStatus) {
      if (window.confirm('You have unsaved changes. Are you sure you want to select another student?')) {
        onSelectStudent(id);
      }
    } else {
      onSelectStudent(id);
    }
  };

  const toggleGroup = (className: string) => {
    setExpandedGroups(prev => ({
      ...prev,
      [className]: !prev[className],
    }));
  };

  return (
    <div className="container">
      <SearchBar onSearch={setSearchTerm} />
      <ul className="Student-list">
        {Object.keys(classGroups).map(className => (
          <li key={className}>
            <div className="group-header" onClick={() => toggleGroup(className)}>
              <span className="group-icon">
                {expandedGroups[className] ? '▼' : '▶'}
              </span>
              {className} ({classGroups[className]})
            </div>
            {expandedGroups[className] && (
              <ul className="Student-list-expanded">
                {filteredStudents
                  .filter(student => student.classDesc === className)
                  .map(student => (
                    <li key={student.SRNo} onClick={() => handleSelectStudent(student.SRNo)} className="student-name">
                      {student.studentName}
                    </li>
                  ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StudentList;
