import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';

const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleCardClick = (path: string) => {
    navigate(path);
  };

  const handleLogout = async () => {
    await logout();
    navigate(`/login`);
  };

  return (
    <Container>
      <Row className="mb-3">
        <Col>
          <Button variant="secondary" onClick={handleLogout}>Logout</Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6} md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Students</Card.Title>
              <Card.Text>
                Manage student information and records.
              </Card.Text>
              <Button variant="primary" onClick={() => handleCardClick('/students')}>Go</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Finances</Card.Title>
              <Card.Text>
                Manage financial records and transactions.
              </Card.Text>
              <Button variant="primary" onClick={() => handleCardClick('/finances')}>Go</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Calendar Planning</Card.Title>
              <Card.Text>
                Manage academic calendar and events.
              </Card.Text>
              <Button variant="primary" onClick={() => handleCardClick('/calendar')}>Go</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Academic Planning</Card.Title>
              <Card.Text>
                Plan and manage academic syllabus and schedules.
              </Card.Text>
              <Button variant="primary" onClick={() => handleCardClick('/academic-planning')}>Go</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LandingPage;
