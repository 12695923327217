import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider, useAuth } from './context/AuthContext';
import LoginPage from './components/LoginPage';
import LandingPage from './components/LandingPage';
import StudentPage from './components/StudentPage';
import FinancesPage from './components/FinancesPage';
import NewReceiptForm from './components/NewReceiptForm';
import ReceiptList from './components/ReceiptList';
import CashReceiptsPage from './components/CashReceiptsPage';
import TransactionPage from './components/TransactionPage';
import CalendarPlanningPage from './components/CalendarPlanningPage';
import AcademicPlanningPage from './components/AcademicPlanningPage';

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated, checkAuth } = useAuth();
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const check = async () => {
      await checkAuth();
      setIsChecking(false);
    };
    check();
  }, [checkAuth]);
  if (isChecking) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <>{children}</> : <Navigate to="/login" replace />;
};

const App: React.FC = () => {
  return (
    <GoogleOAuthProvider clientId="5153499728-l14idtoggqr4j5ckbcvnr9tv1j24vh56.apps.googleusercontent.com">
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/landing" element={<ProtectedRoute><LandingPage /></ProtectedRoute>} />
            <Route path="/students" element={<ProtectedRoute><StudentPage /></ProtectedRoute>} />
            <Route path="/students/:SRNo" element={<ProtectedRoute><StudentPage /></ProtectedRoute>} />
            <Route path="/finances" element={<ProtectedRoute><FinancesPage /></ProtectedRoute>} />
            <Route path="/finances/new-receipt" element={<ProtectedRoute><NewReceiptForm /></ProtectedRoute>} />
            <Route path="/finances/receipts" element={<ProtectedRoute><CashReceiptsPage /></ProtectedRoute>} />
            <Route path="/finances/transactions" element={<ProtectedRoute><TransactionPage /></ProtectedRoute>} />
            <Route path="/calendar" element={<ProtectedRoute><CalendarPlanningPage /></ProtectedRoute>} />
            <Route path="/academic-planning" element={<ProtectedRoute><AcademicPlanningPage /></ProtectedRoute>} />
            <Route path="/" element={<Navigate to="/landing" replace />} />
          </Routes>
        </Router>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
