import React from 'react';
import Tabs from './Tabs';
import PersonalDetails from './PersonalDetails';
import AcademicProgress from './AcademicProgress';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row } from 'react-bootstrap';

interface Props {
  SRNo: string;
  setUnsavedChangeStatus: (arg: boolean) => void;
}

const StudentDetails: React.FC<Props> = ({ SRNo, setUnsavedChangeStatus }) => {
  const tabs = [
    {
      title: 'Personal Details',
      content: <PersonalDetails SRNo={SRNo} setHasUnsavedChanges={setUnsavedChangeStatus} />
    },
    {
      title: 'Academic Progress',
      content: <AcademicProgress SRNo={SRNo} />
    }
  ];

  return (
    <Container>
      <Row>
        <Col>
          <Tabs tabs={tabs} />
        </Col>
      </Row>
    </Container>
  );
};

export default StudentDetails;
