import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';

const FinancesPage: React.FC = () => {
  const navigate = useNavigate();

  const handleCardClick = (path: string) => {
    navigate(path);
  };

  const options = [
    { name: 'Cash Receipts', path: '/finances/receipts', enabled: true },
    { name: 'Transactions', path: '/finances/transactions', enabled: true }
  ];

  return (
    <Container>
      <h2>Finances</h2>
      <Row>
        {options.map((option, index) => (
          <Col key={index} xs={12} sm={6} md={4}>
            <Card>
              <Card.Body>
                <Card.Title>{option.name}</Card.Title>
                <Card.Text>
                  {option.enabled ? 'Manage ' + option.name.toLowerCase() + '.' : 'This option is currently disabled.'}
                </Card.Text>
                <Button 
                  variant="primary" 
                  onClick={() => handleCardClick(option.path)} 
                  disabled={!option.enabled}
                >
                  {option.enabled ? 'Go' : 'Disabled'}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default FinancesPage;