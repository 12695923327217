import React, { useState, useEffect } from 'react';
import { Table, Form, Button, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import NewTransactionForm from './NewTransactionForm';
import { ITransaction, TransactionType } from '../types/transaction';
import "react-datepicker/dist/react-datepicker.css";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3002';

const TransactionList: React.FC = () => {
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [searchName, setSearchName] = useState('');
  const [searchType, setSearchType] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [editingTransactionId, setEditingTransactionId] = useState<string | null>(null);

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/accountsapi/getAllTransactions`);
      if (!response.ok) {
        throw new Error('Failed to fetch transactions');
      }
      const data = await response.json();
      // Sort transactions by date in descending order (most recent first)
      const sortedTransactions = data.sort((a: ITransaction, b: ITransaction) => 
        new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      setTransactions(sortedTransactions);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const handleSearch = async () => {
    try {
      let queryParams = new URLSearchParams();
      if (searchName) queryParams.append('name', searchName);
      if (searchType) queryParams.append('type', searchType);
      if (startDate) queryParams.append('startDate', startDate.toISOString().split('T')[0]);
      if (endDate) queryParams.append('endDate', endDate.toISOString().split('T')[0]);

      const response = await fetch(`${BACKEND_URL}/accountsapi/getAllTransactions?${queryParams.toString()}`);
      if (!response.ok) {
        throw new Error('Failed to search transactions');
      }
      const data = await response.json();
      // Sort transactions by date in descending order (most recent first)
      const sortedTransactions = data.sort((a: ITransaction, b: ITransaction) => 
        new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      setTransactions(sortedTransactions);
    } catch (error) {
      console.error('Error searching transactions:', error);
    }
  };

  const handleEdit = (transactionId: string) => {
    setEditingTransactionId(transactionId);
  };

  const handleEditComplete = () => {
    setEditingTransactionId(null);
    fetchTransactions(); // Refresh the list after edit
  };

  const handleCancelEdit = () => {
    setEditingTransactionId(null);
  };

  return (
    <div>
      <Form className="mb-3">
        <Row>
          <Col md={3}>
            <Form.Group className="mb-2">
              <Form.Control
                type="text"
                placeholder="Search by purpose"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="mb-2">
              <Form.Control
                as="select"
                value={searchType}
                onChange={(e) => setSearchType(e.target.value)}
              >
                <option value="">All Types</option>
                {Object.values(TransactionType).map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group className="mb-2">
              <DatePicker
                selected={startDate}
                onChange={(date: Date | null) => setStartDate(date)}
                placeholderText="Start Date"
                className="form-control"
                dateFormat="dd/MM/yyyy"
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group className="mb-2">
              <DatePicker
                selected={endDate}
                onChange={(date: Date | null) => setEndDate(date)}
                placeholderText="End Date"
                className="form-control"
                dateFormat="dd/MM/yyyy"
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Button onClick={handleSearch}>Search</Button>
          </Col>
        </Row>
      </Form>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Purpose</th>
            <th>Method</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => (
            <React.Fragment key={transaction._id}>
              <tr>
                <td>{new Date(transaction.date).toLocaleDateString()}</td>
                <td>{transaction.type}</td>
                <td>{transaction.amount}</td>
                <td>{transaction.purpose}</td>
                <td>{transaction.method}</td>
                <td>
                  <FontAwesomeIcon 
                    icon={faPencilAlt} 
                    onClick={() => handleEdit(transaction._id)}
                    style={{ cursor: 'pointer' }}
                  />
                </td>
              </tr>
              {editingTransactionId === transaction._id && (
                <tr>
                  <td colSpan={6}>
                    <NewTransactionForm
                      initialData={transaction}
                      onComplete={handleEditComplete}
                      onCancel={handleCancelEdit}
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TransactionList;
