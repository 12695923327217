import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import NewReceiptForm from './NewReceiptForm';
import ReceiptList from './ReceiptList';

const CashReceiptsPage = () => {
  const [key, setKey] = useState('new');

  return (
    <div className="container mt-4">
      <h1>Cash Receipts</h1>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k: string | null) => setKey(k || 'new')}        className="mb-3"
      >
        <Tab eventKey="new" title="New Receipt">
          <NewReceiptForm />
        </Tab>
        <Tab eventKey="view" title="View/Edit Receipts">
          <ReceiptList />
        </Tab>
      </Tabs>
    </div>
  );
};

export default CashReceiptsPage;