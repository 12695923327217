export enum TransactionType {
  DEPOSIT = 'deposit',
  WITHDRAWAL = 'withdrawal',
  PAYMENT = 'payment',
}

export enum TransactionMethod {
  CASH = 'cash',
  UPI = 'UPI',
  CHEQUE = 'cheque',
  NEFT = 'NEFT',
}

export interface ITransactionInput {
  type: TransactionType;
  date: string | Date;
  amount: number;
  purpose: string;
  method: TransactionMethod;
  metadata: {
    upiId?: string;
    chequeNumber?: string;
    bankName?: string;
    accountNumber?: string;
    utrNumber?: string;
    [key: string]: string | undefined;
  };
}

export interface ITransaction extends Omit<ITransactionInput, 'date'> {
  _id: string;
  date: Date;
}
