import React, { useState, useEffect } from 'react';
import { parse, format } from 'date-fns';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PersonalDetails.css';

interface Student {
  studentName: string;
  dob: string;
  motherName: string;
  fatherName: string;
  aadhaarNo: string;
}

interface FieldConfig {
  label: string;
  type: 'text' | 'date' | 'boolean' | 'select';
  options?: { value: string | number; label: string }[];
}

interface Props {
  SRNo: string;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
}

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3002';

const fieldConfigs: { [key in keyof Student]: FieldConfig } = {
  studentName: { label: 'Student Name', type: 'text' },
  dob: { label: 'Date of Birth', type: 'date' },
  motherName: { label: "Mother's Name", type: 'text' },
  fatherName: { label: "Father's Name", type: 'text' },
  aadhaarNo: { label: 'Aadhaar Number', type: 'text' }
};

const PersonalDetails: React.FC<Props> = ({ SRNo, setHasUnsavedChanges }) => {
  const [student, setStudent] = useState<Student | null>(null);
  const [editingField, setEditingField] = useState<keyof Student | null>(null);

  useEffect(() => {
    if (SRNo) {
      fetch(`${BACKEND_URL}/studentapi/students/${SRNo}/personal-details`)
        .then(response => response.json())
        .then(data => setStudent(data))
        .catch(error => console.error('Error fetching student details:', error));
    }
  }, [SRNo]);

  const handleChange = (value: any, field: keyof Student) => {
    if (student) {
      let newValue = value;
      if (field === 'dob' && value instanceof Date) {
        newValue = format(value, 'dd/MM/yyyy');
      }
      setStudent({
        ...student,
        [field]: newValue,
      });
      setHasUnsavedChanges(true);
    }
  };

  const handleSave = (field: keyof Student) => {
    if (student) {
      fetch(`${BACKEND_URL}/studentapi/students/${SRNo}/personal-details`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ [field]: student[field] }),
      })
        .then(response => response.json())
        .then(() => {
          setHasUnsavedChanges(false);
          setEditingField(null);
        })
        .catch(error => console.error('Error saving student details:', error));
    }
  };

  const handleEditClick = (field: keyof Student) => {
    if (editingField === field) {
      handleSave(field);
    } else {
      setEditingField(field);
    }
  };

  if (!student) {
    return <div>Loading personal details...</div>;
  }

  return (
    <Container>
      <h2 className="student-name">{student.studentName}</h2>
      <Form>
        <Row className="form-section">
          {Object.entries(fieldConfigs).map(([key, config]) => (
            <Col md={6} key={key}>
              <Form.Group className="form-group">
                <Form.Label>{config.label}:</Form.Label>
                {editingField === key ? (
                  config.type === 'select' ? (
                    <Form.Control
                      as="select"
                      value={student[key as keyof Student]}
                      onChange={(e: React.ChangeEvent<any>) => handleChange(e.target.value, key as keyof Student)}
                    >
                      {config.options?.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                  ) : config.type === 'date' ? (
                    <DatePicker
                    selected={student[key as keyof Student] ? parse(student[key as keyof Student], 'dd/MM/yyyy', new Date()) : null}
                    onChange={(date: Date | null) => handleChange(date, key as keyof Student)}
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date("01/01/1900")}
                    maxDate={new Date()}
                    />
                  ) : (
                    <Form.Control
                      type={config.type}
                      value={student[key as keyof Student]}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, key as keyof Student)}
                    />
                  )
                ) : (
                  <span className="field-value">{student[key as keyof Student]}</span>
                )}
                <Button
                  variant="link"
                  onClick={() => handleEditClick(key as keyof Student)}
                >
                  <FontAwesomeIcon icon={editingField === key ? faSave : faPencilAlt} />
                </Button>
              </Form.Group>
            </Col>
          ))}
        </Row>
      </Form>
    </Container>
  );
};

export default PersonalDetails;
