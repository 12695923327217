import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Modal, Button } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { Student, ChequeDetails, Receipt, SplitPaymentDetails } from '../types';
import StudentDetails from './StudentDetails';

interface NewReceiptFormProps {
  initialData?: Receipt;
  onComplete?: () => void;
  onCancel?: () => void;
}
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3002';



const NewReceiptForm: React.FC<NewReceiptFormProps> = ({ initialData, onComplete, onCancel }) => {
  const [receiptId, setReceiptId] = useState<string>(initialData?.receiptId || '');
  const [receiptDate, setReceiptDate] = useState<string>(initialData?.date || '');
  const [receiptType, setReceiptType] = useState<string>(initialData?.receiptType || 'fees');
  const [studentName, setStudentName] = useState<string>(initialData?.studentDetails?.studentName || '');
  const [selectedStudent, setSelectedStudent] = useState<Student | null>(
    initialData?.studentDetails || null
  );
  const [amount, setAmount] = useState<string>(initialData?.amount ? initialData.amount.toString() : '');
  const [depositMedium, setDepositMedium] = useState<string>(initialData?.depositMedium || 'cash');
  const [upiId, setUpiId] = useState<string>(initialData?.upiId || '');
  const [chequeDetails, setChequeDetails] = useState<ChequeDetails>(initialData?.chequeDetails || { number: '', bankName: '', accountNumber: '' });
  const [feeTypes, setFeeTypes] = useState<string[]>(initialData?.feeTypes || []);
  const [students, setStudents] = useState<Student[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [showModal, setShowModal] = useState(false);
  const [cancellationReason, setCancellationReason] = useState<string>(initialData?.cancellationReason || '');
  const [donorName, setDonorName] = useState<string>(initialData?.donorName || ''); // New state for donor name
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [receiptToConfirm, setReceiptToConfirm] = useState<any>(null);

  // Update the initialization of splitPaymentDetails
  const [splitPaymentDetails, setSplitPaymentDetails] = useState<SplitPaymentDetails>({
    cashAmount: initialData?.splitPaymentDetails?.cashAmount?.toString() || '',
    upiAmount: initialData?.splitPaymentDetails?.upiAmount?.toString() || '',
    upiId: initialData?.splitPaymentDetails?.upiId || '',
  });

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    if (!initialData) {
      fetchNextReceiptId();
    }
  }, [initialData]);

  const fetchNextReceiptId = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/accountsapi/receipts/next-id`);
      if (!response.ok) {
        throw new Error('Failed to fetch next receipt ID');
      }
      const data = await response.json();
      setReceiptId(data.nextId);
    } catch (error) {
      console.error('Error fetching next receipt ID:', error);
    }
  };

  const handleStudentSearch = async (query: string) => {
    setStudentName(query);
    if (query.length > 2) {
      try {
        const response = await fetch(`${BACKEND_URL}/studentapi/students/search?name=${encodeURIComponent(query)}`);
        if (!response.ok) {
          throw new Error('Failed to fetch students');
        }
        const data = await response.json();
        setStudents(data);
      } catch (error) {
        console.error('Error searching students:', error);
        setStudents([]);
      }
    } else {
      setStudents([]);
    }
  };

  const handleStudentSelect = (student: Student) => {
    setSelectedStudent(student);
    setStudentName(student.studentName);
    setStudents([]);
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    if (!receiptDate) newErrors.receiptDate = "Receipt date is required";
    if (receiptType !== 'cancelled' && (!amount || parseFloat(amount) <= 0)) newErrors.amount = "Valid amount is required";
    if (receiptType === 'fees' && !selectedStudent) newErrors.studentName = "Student selection is required for fees";
    if (receiptType === 'donation' && !donorName) newErrors.donorName = "Donor name is required"; // Validate donor name
    if (depositMedium === 'upi' && !upiId) newErrors.upiId = "UPI ID is required for UPI payments";
    if (depositMedium === 'cheque') {
      if (!chequeDetails.number) newErrors.chequeNumber = "Cheque number is required";
      if (!chequeDetails.bankName) newErrors.bankName = "Bank name is required";
      if (!chequeDetails.accountNumber) newErrors.accountNumber = "Account number is required";
    }
    if (receiptType === 'cancelled' && !cancellationReason) newErrors.cancellationReason = "Cancellation reason is required";

    if (depositMedium === 'split') {
      if (!splitPaymentDetails.cashAmount || parseFloat(splitPaymentDetails.cashAmount) <= 0) {
        newErrors.cashAmount = "Valid cash amount is required";
      }
      if (!splitPaymentDetails.upiAmount || parseFloat(splitPaymentDetails.upiAmount) <= 0) {
        newErrors.upiAmount = "Valid UPI amount is required";
      }
      if (!splitPaymentDetails.upiId) {
        newErrors.splitUpiId = "UPI ID is required for split payments";
      }
      const totalAmount = parseFloat(splitPaymentDetails.cashAmount) + parseFloat(splitPaymentDetails.upiAmount);
      if (totalAmount !== parseFloat(amount)) {
        newErrors.splitTotal = "Total split amount must equal the receipt amount";
      }
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      setErrorMessages(Object.values(newErrors));
      setShowErrorModal(true);
      return false;
    }
    return true;
  };

  const handleWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    // This variable holds the data for the receipt that will be submitted to the server.
    // The structure of this object depends on the type of receipt and the selected options.
    // It includes conditional properties based on the receipt type and deposit medium.
    const receiptData: any = {
      receiptId, // The unique ID of the receipt
      date: receiptDate, // The date of the receipt
      receiptType: receiptType, // The type of the receipt (e.g., fees, donation, cancelled)
      ...(receiptType !== 'cancelled' && { amount: parseFloat(amount) }), // The amount, if the receipt is not cancelled
      ...(receiptType !== 'cancelled' && { depositMedium }), // The deposit medium, if the receipt is not cancelled
      ...(receiptType === 'fees' && { 
        studentDetails: {
          _id: selectedStudent?._id, // The ID of the selected student
          studentName: selectedStudent?.studentName, // The name of the selected student
          srno: selectedStudent?.srno, // The SR number of the selected student
          classDesc: selectedStudent?.classDesc // The class description of the selected student
        }
      }),
      ...(receiptType === 'donation' && { donorName }), // The donor name, if the receipt type is donation
      ...(depositMedium === 'upi' && { upiId }), // The UPI ID, if the deposit medium is UPI
      ...(depositMedium === 'cheque' && { chequeDetails }), // The cheque details, if the deposit medium is cheque
      ...(depositMedium === 'split' && { 
        splitPaymentDetails: {
          cashAmount: parseFloat(splitPaymentDetails.cashAmount),
          upiAmount: parseFloat(splitPaymentDetails.upiAmount),
          upiId: splitPaymentDetails.upiId
        } 
      }),
      ...(receiptType === 'fees' && { feeTypes }), // The fee types, if the receipt type is fees
      ...(receiptType === 'cancelled' && { cancellationReason }), // The cancellation reason, if the receipt type is cancelled
    };

    setReceiptToConfirm(receiptData);
    setShowConfirmModal(true);
  };

  const handleConfirmSubmit = async () => {
    try {
      const url = initialData 
        ? `${BACKEND_URL}/accountsapi/receipts/${initialData._id}`
        : `${BACKEND_URL}/accountsapi/receipts`;
      const method = initialData ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(receiptToConfirm),
      });

      if (!response.ok) {
        throw new Error('Failed to save receipt');
      }

      const savedReceipt = await response.json();
      console.log('Receipt saved:', savedReceipt);
      
      setShowConfirmModal(false);
      setShowModal(true);
      if (onComplete) onComplete();
    } catch (error) {
      console.error('Error saving receipt:', error);
      alert('Failed to save receipt. Please try again.');
    }
  };

  const handleCancelSubmit = () => {
    setShowConfirmModal(false);
    setReceiptToConfirm(null);
    if (onCancel) onCancel();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    clearForm();
  };

  const clearForm = () => {
    setReceiptDate('');
    setReceiptType('fees');
    setStudentName('');
    setSelectedStudent(null);
    setAmount('');
    setDepositMedium('cash');
    setUpiId('');
    setChequeDetails({ number: '', bankName: '', accountNumber: '' });
    setFeeTypes([]);
    setCancellationReason('');
    setDonorName(''); // Clear donor name
    setErrors({});
    fetchNextReceiptId();
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="mt-3">
        <div className="mb-3">
          <label htmlFor="receiptId" className="form-label">Receipt ID</label>
          <input type="text" className="form-control" id="receiptId" value={receiptId} readOnly />
        </div>

        <div className="mb-3">
          <label htmlFor="receiptDate" className="form-label">Receipt Date</label>
          <DatePicker
            selected={receiptDate ? new Date(receiptDate) : null}
            onChange={(date: Date | null) => setReceiptDate(date ? date.toISOString().split('T')[0] : '')}
            className={`form-control ${errors.receiptDate ? 'is-invalid' : ''}`}
            dateFormat="dd/MM/yyyy"
            id="receiptDate"
            minDate={new Date("01/01/1900")}
            maxDate={new Date()}
          />
          {errors.receiptDate && <div className="invalid-feedback">{errors.receiptDate}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="receiptType" className="form-label">Receipt Type</label>
          <select 
            className="form-select" 
            id="receiptType" 
            value={receiptType} 
            onChange={(e) => {
              setReceiptType(e.target.value);
              if (e.target.value === 'donation' && (depositMedium === 'cash' || depositMedium === 'split')) {
                setDepositMedium('upi'); // Default to UPI when changing to donation
              }
            }}
          >
            <option value="fees">Fees</option>
            <option value="donation">Loan/Donation</option>
            <option value="cancelled">Cancelled</option>
          </select>
        </div>

        {receiptType === 'cancelled' && (
          <div className="mb-3">
            <label htmlFor="cancellationReason" className="form-label">Cancellation Reason</label>
            <textarea 
              className={`form-control ${errors.cancellationReason ? 'is-invalid' : ''}`}
              id="cancellationReason" 
              value={cancellationReason} 
              onChange={(e) => setCancellationReason(e.target.value)}
            />
            {errors.cancellationReason && <div className="invalid-feedback">{errors.cancellationReason}</div>}
          </div>
        )}

        {receiptType === 'donation' && (
          <div className="mb-3">
            <label htmlFor="donorName" className="form-label">Donor Name</label>
            <input 
              type="text" 
              className={`form-control ${errors.donorName ? 'is-invalid' : ''}`}
              id="donorName" 
              value={donorName} 
              onChange={(e) => setDonorName(e.target.value)}
            />
            {errors.donorName && <div className="invalid-feedback">{errors.donorName}</div>}
          </div>
        )}

        {receiptType !== 'cancelled'  && (
          <>
            {receiptType === 'fees' && (
              <div className="mb-3">
                <label htmlFor="studentName" className="form-label">Student Name</label>
                <input 
                  type="text" 
                  className={`form-control ${errors.studentName ? 'is-invalid' : ''}`}
                  id="studentName" 
                  value={studentName} 
                  onChange={(e) => handleStudentSearch(e.target.value)}
                />
                {errors.studentName && <div className="invalid-feedback">{errors.studentName}</div>}
                {students.length > 0 && (
                  <ul className="list-group mt-2">
                    {students.map(student => (
                      <li 
                        key={student._id} 
                        className="list-group-item list-group-item-action"
                        onClick={() => handleStudentSelect(student)}
                      >
                        {student.studentName} ({student.classDesc})
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}

            {selectedStudent && (
              <div className="mb-3">
                <p>SR Number: {selectedStudent.srno}</p>
                <p>Class: {selectedStudent.classDesc}</p>
              </div>
            )}

            <div className="mb-3">
              <label htmlFor="amount" className="form-label">Amount</label>
              <input 
                type="number" 
                className={`form-control ${errors.amount ? 'is-invalid' : ''}`}
                id="amount" 
                value={amount} 
                onWheel={handleWheel}
                onChange={(e) => setAmount(e.target.value)}
              />
              {errors.amount && <div className="invalid-feedback">{errors.amount}</div>}
            </div>

            <div className="mb-3">
              <label htmlFor="depositMedium" className="form-label">Deposit Medium</label>
              <select 
                className="form-select" 
                id="depositMedium" 
                value={depositMedium} 
                onChange={(e) => setDepositMedium(e.target.value)}
              >
                {receiptType !== 'donation' && <option value="cash">Cash</option>}
                <option value="upi">UPI</option>
                <option value="cheque">Cheque</option>
                {receiptType !== 'donation' && <option value="split">Split Payment (Cash + UPI)</option>}
              </select>
            </div>

            {depositMedium === 'upi' && (
              <div className="mb-3">
                <label htmlFor="upiId" className="form-label">UPI ID</label>
                <input 
                  type="text" 
                  className={`form-control ${errors.upiId ? 'is-invalid' : ''}`}
                  id="upiId" 
                  value={upiId} 
                  onChange={(e) => setUpiId(e.target.value)}
                />
                {errors.upiId && <div className="invalid-feedback">{errors.upiId}</div>}
              </div>
            )}

            {depositMedium === 'cheque' && (
              <>
                <div className="mb-3">
                  <label htmlFor="chequeNumber" className="form-label">Cheque Number</label>
                  <input 
                    type="text" 
                    className={`form-control ${errors.chequeNumber ? 'is-invalid' : ''}`}
                    id="chequeNumber" 
                    value={chequeDetails.number} 
                    onChange={(e) => setChequeDetails({...chequeDetails, number: e.target.value})}
                  />
                  {errors.chequeNumber && <div className="invalid-feedback">{errors.chequeNumber}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="bankName" className="form-label">Bank Name</label>
                  <input 
                    type="text" 
                    className={`form-control ${errors.bankName ? 'is-invalid' : ''}`}
                    id="bankName" 
                    value={chequeDetails.bankName} 
                    onChange={(e) => setChequeDetails({...chequeDetails, bankName: e.target.value})}
                  />
                  {errors.bankName && <div className="invalid-feedback">{errors.bankName}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="accountNumber" className="form-label">Account Number</label>
                  <input 
                    type="text" 
                    className={`form-control ${errors.accountNumber ? 'is-invalid' : ''}`}
                    id="accountNumber" 
                    value={chequeDetails.accountNumber} 
                    onChange={(e) => setChequeDetails({...chequeDetails, accountNumber: e.target.value})}
                  />
                  {errors.accountNumber && <div className="invalid-feedback">{errors.accountNumber}</div>}
                </div>
              </>
            )}

            {depositMedium === 'split' && (
              <div className="mb-3">
                <label htmlFor="cashAmount" className="form-label">Cash Amount</label>
                <input 
                  type="number" 
                  className={`form-control ${errors.cashAmount ? 'is-invalid' : ''}`}
                  id="cashAmount" 
                  value={splitPaymentDetails.cashAmount} 
                  onWheel={handleWheel}
                  onChange={(e) => setSplitPaymentDetails({...splitPaymentDetails, cashAmount: e.target.value})}
                />
                {errors.cashAmount && <div className="invalid-feedback">{errors.cashAmount}</div>}

                <label htmlFor="upiAmount" className="form-label mt-2">UPI Amount</label>
                <input 
                  type="number" 
                  className={`form-control ${errors.upiAmount ? 'is-invalid' : ''}`}
                  id="upiAmount" 
                  value={splitPaymentDetails.upiAmount} 
                  onWheel={handleWheel}
                  onChange={(e) => setSplitPaymentDetails({...splitPaymentDetails, upiAmount: e.target.value})}
                />
                {errors.upiAmount && <div className="invalid-feedback">{errors.upiAmount}</div>}

                <label htmlFor="splitUpiId" className="form-label mt-2">UPI ID</label>
                <input 
                  type="text" 
                  className={`form-control ${errors.splitUpiId ? 'is-invalid' : ''}`}
                  id="splitUpiId" 
                  value={splitPaymentDetails.upiId} 
                  onChange={(e) => setSplitPaymentDetails({...splitPaymentDetails, upiId: e.target.value})}
                />
                {errors.splitUpiId && <div className="invalid-feedback">{errors.splitUpiId}</div>}
                {errors.splitTotal && <div className="invalid-feedback">{errors.splitTotal}</div>}
              </div>
            )}

            {receiptType === 'fees' && (
              <div className="mb-3">
                <label className="form-label">Fee Types</label>
                {['Annual Fee', 'Admission Fee', 'January Fee', 'February Fee', 'March Fee', 'April Fee', 'May Fee', 'June Fee', 'July Fee', 'August Fee', 'September Fee', 'October Fee', 'November Fee', 'December Fee'].map((feeType) => (
                  <div className="form-check" key={feeType}>
                    <input 
                      className="form-check-input" 
                      type="checkbox" 
                      id={feeType} 
                      checked={feeTypes.includes(feeType)} 
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFeeTypes([...feeTypes, feeType]);
                        } else {
                          setFeeTypes(feeTypes.filter(type => type !== feeType));
                        }
                      }}
                    />
                    <label className="form-check-label" htmlFor={feeType}>
                      {feeType}
                    </label>
                  </div>
                ))}
              </div>
            )}
          </>
        )}

        <div className="mt-3">
          <button type="submit" className="btn btn-primary me-2">Submit</button>
          {onCancel && <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancel</button>}
        </div>
      </form>

      <Modal show={showConfirmModal} onHide={handleCancelSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Receipt Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Receipt ID: {receiptToConfirm?.receiptId}</p>
          <p>Date: {receiptToConfirm?.date}</p>
          <p>Type: {receiptToConfirm?.receiptType}</p>
          {receiptToConfirm?.amount && <p>Amount: {receiptToConfirm.amount}</p>}
          {receiptToConfirm?.studentDetails && (
            <>
              <p>Student Name: {receiptToConfirm.studentDetails.studentName}</p>
              <p>SR Number: {receiptToConfirm.studentDetails.srno}</p>
              <p>Class: {receiptToConfirm.studentDetails.classDesc}</p>
            </>
          )}
          {receiptToConfirm?.donorName && <p>Donor Name: {receiptToConfirm.donorName}</p>}
          {receiptToConfirm?.depositMedium && <p>Deposit Medium: {receiptToConfirm.depositMedium}</p>}
          {receiptToConfirm?.upiId && <p>UPI ID: {receiptToConfirm.upiId}</p>}
          {receiptToConfirm?.chequeDetails && (
            <div>
              <p>Cheque Number: {receiptToConfirm.chequeDetails.number}</p>
              <p>Bank Name: {receiptToConfirm.chequeDetails.bankName}</p>
              <p>Account Number: {receiptToConfirm.chequeDetails.accountNumber}</p>
            </div>
          )}
          {receiptToConfirm?.splitPaymentDetails && (
            <div>
              <p>Cash Amount: {receiptToConfirm.splitPaymentDetails.cashAmount}</p>
              <p>UPI Amount: {receiptToConfirm.splitPaymentDetails.upiAmount}</p>
              <p>UPI ID: {receiptToConfirm.splitPaymentDetails.upiId}</p>
            </div>
          )}
          {receiptToConfirm?.feeTypes && <p>Fee Types: {receiptToConfirm.feeTypes.join(', ')}</p>}
          {receiptToConfirm?.cancellationReason && <p>Cancellation Reason: {receiptToConfirm.cancellationReason}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelSubmit}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmSubmit}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Receipt saved successfully!</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Error Modal */}
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Form Validation Errors</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {errorMessages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewReceiptForm;
