import React, { useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import SyllabusPlanningComponent from './SyllabusPlanningComponent';

const AcademicPlanningPage: React.FC = () => {
  const [academicYear, setAcademicYear] = useState<string>('');
  const [className, setClassName] = useState<string>('');

  return (
    <Container fluid style={{ height: 'calc(100vh - 100px)' }}>
      <h1>Academic Planning</h1>
      <Row className="mb-3">
        <Col md={6}>
          <Form.Group>
            <Form.Label>Select Academic Year</Form.Label>
            <Form.Control
              as="select"
              value={academicYear}
              onChange={(e) => setAcademicYear(e.target.value)}
            >
              <option value="">Select Year</option>
              <option value="2023-2024">2023-2024</option>
              <option value="2024-2025">2024-2025</option>
              <option value="2025-2026">2025-2026</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>Select Class</Form.Label>
            <Form.Control
              as="select"
              value={className}
              onChange={(e) => setClassName(e.target.value)}
            >
              <option value="">Select Class</option>
              <option value="1">Class 1</option>
              <option value="2">Class 2</option>
              <option value="3">Class 3</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      {academicYear && className && (
        <div style={{ height: 'calc(100% - 100px)' }}>
          <SyllabusPlanningComponent academicYear={academicYear} className={className} />
        </div>
      )}
    </Container>
  );
};

export default AcademicPlanningPage;
