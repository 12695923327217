import React, { useState } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import NewTransactionForm from './NewTransactionForm';
import TransactionList from './TransactionList';

const TransactionPage: React.FC = () => {
  const [key, setKey] = useState<string>('new');

  return (
    <Container>
      <h1 className="my-4">Transactions</h1>
      <Tabs
        id="transaction-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k || 'new')}
        className="mb-3"
      >
        <Tab eventKey="new" title="New Transaction">
          <NewTransactionForm />
        </Tab>
        <Tab eventKey="list" title="Transaction List">
          <TransactionList />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default TransactionPage;
