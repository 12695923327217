// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.student-name {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .section-header {
    font-size: 1.25rem;
    margin-bottom: 10px;
    color: #007bff;
  }
  
  .form-section {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .form-group label {
    flex: 1 1;
    font-weight: bold;
  }
  
  .field-value {
    flex: 2 1;
    padding: 5px;
    background-color: #e9ecef;
    border-radius: 4px;
  }
  
  button {
    margin-left: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/PersonalDetails.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,SAAO;IACP,iBAAiB;EACnB;;EAEA;IACE,SAAO;IACP,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":[".student-name {\n    font-size: 1.5rem;\n    margin-bottom: 20px;\n    text-align: center;\n  }\n  \n  .section-header {\n    font-size: 1.25rem;\n    margin-bottom: 10px;\n    color: #007bff;\n  }\n  \n  .form-section {\n    margin-bottom: 20px;\n    padding: 10px;\n    border-radius: 8px;\n    background-color: #f9f9f9;\n  }\n  \n  .form-group {\n    display: flex;\n    align-items: center;\n    margin-bottom: 15px;\n  }\n  \n  .form-group label {\n    flex: 1;\n    font-weight: bold;\n  }\n  \n  .field-value {\n    flex: 2;\n    padding: 5px;\n    background-color: #e9ecef;\n    border-radius: 4px;\n  }\n  \n  button {\n    margin-left: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
