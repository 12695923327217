// AcademicProgress.tsx
import React, { useState, useEffect } from 'react';

interface Props {
  SRNo: string;
}
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3002';

const AcademicProgress: React.FC<Props> = ({ SRNo }) => {
  const [academicProgress, setAcademicProgress] = useState<any>(null);

  useEffect(() => {
    if (SRNo) {
      fetch(`${BACKEND_URL}/studentapi/students/${SRNo}/academic-progress`)
        .then(response => response.json())
        .then(data => setAcademicProgress(data))
        .catch(error => console.error('Error fetching academic progress:', error));
    }
  }, [SRNo]);

  if (!academicProgress) {
    return <div>Loading academic progress...</div>;
  }

  return (
    <div>
      <p>Academic progress details will be here.</p>
      {/* Render academic progress details here */}
    </div>
  );
};

export default AcademicProgress;
