// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.syllabus-planning-component {
  width: 100%;
  height: 800px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
}

.table-header {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #f0f0f0;
}

.table-body {
  flex-grow: 1;
  overflow-y: auto;
}

.table-row {
  display: flex;
  height: 30px;
}

.table-row.sunday {
  background-color: #f5f5f5;
}

.header-cell, .cell {
  flex: 1 1;
  min-width: 150px;
  padding: 5px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
}

.date-header, .date-cell {
  flex: 0 0 200px;
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #f0f0f0;
}

.event-header, .event-cell {
  flex: 0 0 250px;
}

.header-cell {
  font-weight: bold;
  justify-content: center;
  background-color: #e0e0e0;
}

.date-cell {
  justify-content: flex-start;
}

.event-cell, .subject-cell {
  justify-content: flex-start;
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/SyllabusPlanningComponent.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,MAAM;EACN,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,SAAO;EACP,gBAAgB;EAChB,YAAY;EACZ,4BAA4B;EAC5B,6BAA6B;EAC7B,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,OAAO;EACP,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,uBAAuB;EACvB,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B;EAC3B,eAAe;AACjB","sourcesContent":[".syllabus-planning-component {\n  width: 100%;\n  height: 800px;\n  border: 1px solid #ccc;\n  display: flex;\n  flex-direction: column;\n}\n\n.table-header {\n  display: flex;\n  position: sticky;\n  top: 0;\n  z-index: 2;\n  background-color: #f0f0f0;\n}\n\n.table-body {\n  flex-grow: 1;\n  overflow-y: auto;\n}\n\n.table-row {\n  display: flex;\n  height: 30px;\n}\n\n.table-row.sunday {\n  background-color: #f5f5f5;\n}\n\n.header-cell, .cell {\n  flex: 1;\n  min-width: 150px;\n  padding: 5px;\n  border-right: 1px solid #ccc;\n  border-bottom: 1px solid #ccc;\n  display: flex;\n  align-items: center;\n}\n\n.date-header, .date-cell {\n  flex: 0 0 200px;\n  position: sticky;\n  left: 0;\n  z-index: 1;\n  background-color: #f0f0f0;\n}\n\n.event-header, .event-cell {\n  flex: 0 0 250px;\n}\n\n.header-cell {\n  font-weight: bold;\n  justify-content: center;\n  background-color: #e0e0e0;\n}\n\n.date-cell {\n  justify-content: flex-start;\n}\n\n.event-cell, .subject-cell {\n  justify-content: flex-start;\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
