import React, { useState, useEffect, useCallback } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { Calendar, momentLocalizer, Event } from 'react-big-calendar';
import moment from 'moment-timezone';
import { EventType, ICalendarEvent } from '../types/calendar';
import axios from 'axios';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3002';

// Set the default timezone to IST
moment.tz.setDefault("Asia/Kolkata");

// Setup the localizer for react-big-calendar
const localizer = momentLocalizer(moment);

// Custom end accessor function
const endAccessor = (event: Event) => {
  const end = moment(event.end as Date);
  if (end.hours() === 23 && end.minutes() === 59 && end.seconds() === 59) {
    // If the event ends at 23:59:59, set it to 00:00:00 of the next day
    return end.add(1, 'second').toDate();
  }
  return end.toDate();
};

// Custom event style getter
const eventStyleGetter = (event: Event) => {
  let backgroundColor = '';
  const eventType = (event.resource as ICalendarEvent).eventType;

  switch (eventType) {
    case EventType.HOLIDAY:
      backgroundColor = '#FF0000'; // Red
      break;
    case EventType.EXAM:
      backgroundColor = '#FFA500'; // Orange
      break;
    case EventType.CELEBRATION:
      backgroundColor = '#008000'; // Green
      break;
    case EventType.COMPETITION:
      backgroundColor = '#00FFFF'; // Cyan
      break;
    default:
      backgroundColor = '#3174ad'; // Default blue color
  }

  return {
    style: {
      backgroundColor,
      borderRadius: '0px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block'
    }
  };
};

// Custom day prop getter
const dayPropGetter = (date: Date) => {
  if (date.getDay() === 0) { // Sunday
    return {
      style: {
        backgroundColor: '#FFEEEE', // Light red background for Sundays
      }
    };
  }
  return {};
};

interface CalendarComponentProps {
  academicYear: string;
  events: ICalendarEvent[];
  onEventAdd: (event: ICalendarEvent) => void;
  onEventUpdate: (event: ICalendarEvent) => void;
  onEventDelete: (eventId: string) => void;
  isEditable: boolean;
}

const CalendarComponent: React.FC<CalendarComponentProps> = ({
  academicYear,
  events,
  onEventAdd,
  onEventUpdate,
  onEventDelete,
  isEditable
}) => {
  const [showModal, setShowModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<Partial<ICalendarEvent>>({});
  const [validated, setValidated] = useState(false);
  const [calendarStart, setCalendarStart] = useState<Date>(new Date());
  const [calendarEnd, setCalendarEnd] = useState<Date>(new Date());
  const [calendarEvents, setCalendarEvents] = useState<Event[]>([]);
  const [calendarDate, setCalendarDate] = useState<Date>(new Date());

  const updateCalendarView = useCallback((year: string) => {
    const [startYear, endYear] = year.split('-').map(y => parseInt(y));
    const start = new Date(startYear, 3, 1); // April 1st of start year
    const end = new Date(endYear, 2, 31); // March 31st of end year
    setCalendarStart(start);
    setCalendarEnd(end);

    const currentDate = new Date();
    let newViewDate = new Date(currentDate);

    // Adjust the year to match the academic year
    if (currentDate < start || currentDate > end) {
      newViewDate.setFullYear(startYear);
    }

    // Ensure the date is within the academic year bounds
    if (newViewDate < start) {
      newViewDate = new Date(start);
    } else if (newViewDate > end) {
      newViewDate = new Date(end);
    }

    setCalendarDate(newViewDate);
  }, []);

  useEffect(() => {
    updateCalendarView(academicYear);
    fetchEvents();
  }, [academicYear, updateCalendarView]);

  const fetchEvents = useCallback(async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/calendarapi/events/${academicYear}`);
      setCalendarEvents(response.data.map((event: ICalendarEvent) => ({
        id: event._id,
        title: event.name,
        start: new Date(event.startDate),
        end: new Date(event.endDate),
        allDay: true,
        resource: event
      })));
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  }, [academicYear]);

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentEvent({});
  };

  const handleShowModal = (event?: ICalendarEvent) => {
    if (!isEditable) return; // Prevent opening modal in read-only mode
    if (event) {
      setCurrentEvent(event);
    } else {
      setCurrentEvent({});
    }
    setShowModal(true);
  };

  const handleSaveEvent = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      try {
        const eventToSend = {
          name: currentEvent.name,
          eventType: currentEvent.eventType,
          academicYear,
          startDate: moment(currentEvent.startDate).startOf('day').format(),
          endDate: moment(currentEvent.endDate).endOf('day').format()
        };

        let updatedEvent: ICalendarEvent;
        if (currentEvent._id) {
          // Update existing event
          const response = await axios.put(`${BACKEND_URL}/calendarapi/events/${currentEvent._id}`, eventToSend);
          updatedEvent = response.data;
          onEventUpdate(updatedEvent);
        } else {
          // Create new event
          const response = await axios.post(`${BACKEND_URL}/calendarapi/events`, eventToSend);
          updatedEvent = response.data;
          onEventAdd(updatedEvent);
        }

        // Update local state
        setCalendarEvents(prevEvents => {
          const newEvents = currentEvent._id
            ? prevEvents.map((e: any) => e.id === updatedEvent._id ? {
                id: updatedEvent._id,
                title: updatedEvent.name,
                start: moment(updatedEvent.startDate).toDate(),
                end: moment(updatedEvent.endDate).toDate(),
                allDay: true,
                resource: updatedEvent
              } : e)
            : [...prevEvents, {
                id: updatedEvent._id,
                title: updatedEvent.name,
                start: moment(updatedEvent.startDate).toDate(),
                end: moment(updatedEvent.endDate).toDate(),
                allDay: true,
                resource: updatedEvent
              }];
          return newEvents;
        });

        handleCloseModal();
      } catch (error) {
        console.error('Error saving event:', error);
      }
    }
    setValidated(true);
  };

  const handleDeleteEvent = async (eventId: string) => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      try {
        await axios.delete(`${BACKEND_URL}/calendarapi/events/${eventId}`);
        onEventDelete(eventId);
        // Update local state to remove the deleted event
        setCalendarEvents(prevEvents => prevEvents.filter((event: any) => event.id !== eventId));
        handleCloseModal();
      } catch (error) {
        console.error('Error deleting event:', error);
      }
    }
  };

  return (
    <>
      <Calendar
        localizer={localizer}
        events={calendarEvents}
        startAccessor="start"
        endAccessor={endAccessor}
        style={{ height: 500 }}
        min={calendarStart}
        max={calendarEnd}
        date={calendarDate}
        onNavigate={(date) => setCalendarDate(date)}
        onSelectEvent={(event: Event) => isEditable && handleShowModal(event.resource as ICalendarEvent)}
        onSelectSlot={({ start, end }) => {
          isEditable && handleShowModal({
            name: '',
            eventType: EventType.HOLIDAY,
            academicYear,
            startDate: moment(start).startOf('day').toDate(),
            endDate: moment(end).subtract(1, 'day').endOf('day').toDate()
          });
        }}
        selectable={isEditable}
        eventPropGetter={eventStyleGetter}
        dayPropGetter={dayPropGetter}
      />

      {isEditable && (
        <Button onClick={() => handleShowModal()} className="mt-3">Add New Event</Button>
      )}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Form noValidate validated={validated} onSubmit={handleSaveEvent}>
          <Modal.Header closeButton>
            <Modal.Title>{currentEvent._id ? 'Edit Event' : 'Add New Event'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="eventName">
              <Form.Label>Event Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter event name"
                value={currentEvent.name || ''}
                onChange={(e) => setCurrentEvent({ ...currentEvent, name: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide an event name.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="eventType">
              <Form.Label>Event Type</Form.Label>
              <Form.Control
                as="select"
                value={currentEvent.eventType || ''}
                onChange={(e) => setCurrentEvent({ ...currentEvent, eventType: e.target.value as EventType })}
                required
              >
                <option value="">Select Event Type</option>
                {Object.values(EventType).map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select an event type.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="startDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                value={currentEvent.startDate ? moment(currentEvent.startDate).format('YYYY-MM-DD') : ''}
                onChange={(e) => setCurrentEvent({
                  ...currentEvent,
                  startDate: new Date(e.target.value)
                })}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a start date.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="endDate">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                value={currentEvent.endDate ? moment(currentEvent.endDate).format('YYYY-MM-DD') : ''}
                onChange={(e) => setCurrentEvent({
                  ...currentEvent,
                  endDate: new Date(e.target.value)
                })}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide an end date.
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {currentEvent._id && (
              <Button variant="danger" onClick={() => handleDeleteEvent(currentEvent._id!)}>
                Delete
              </Button>
            )}
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default CalendarComponent;
