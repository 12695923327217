export enum EventType {
  HOLIDAY = 'holiday',
  CELEBRATION = 'celebration',
  COMPETITION = 'competition',
  EXAM = 'exam',
  ANNUAL_FUNCTION = 'annual_function'
}

export interface ICalendarEvent {
  _id?: string;
  name: string;
  eventType: EventType;
  academicYear: string;
  startDate: Date;
  endDate: Date;
}
